import './Main.scss';
import { TypeAnimation } from 'react-type-animation';

const Main = () => {
    return (
        <div className="Main" id='Main'>
            <div className="Main__Center">
                <div className="Main__Center_Name">Nix13</div>
                <TypeAnimation
                    sequence={[
                        'Golang fullstack',
                        1000,
                        'Python fullstack',
                        1000,
                        'Kotlin developer',
                        1000,
                        'Web developer',
                        1000
                    ]}
                    repeat={Infinity}
                    className={'Main__Center_SubName'}
                />
            </div>

            <div className="Main__Links">
                <a className="Main__Links_Link" href='https://t.me/DarkShinigani' target='_blank' rel='noopener noreferrer'>
                    <i className="fa-brands fa-telegram"/>
                </a>
                <a className="Main__Links_Link" href='https://vk.com/scur0nek0' target='_blank' rel='noopener noreferrer'>
                    <i className="fa-brands fa-vk"/>
                </a>
                <a className="Main__Links_Link" href='https://github.com/Nix1304' target='_blank' rel='noopener noreferrer'>
                    <i className="fa-brands fa-github"/>
                </a>
                <a className="Main__Links_Link" href='https://git.nix13.pw/' target='_blank' rel='noopener noreferrer'>
                    <i className="fa-brands fa-git"/>
                </a>
                <a className="Main__Links_Link" href='https://mvn.nix13.pw/' target='_blank' rel='noopener noreferrer'>
                    <i className="fa-brands fa-readme"></i>
                </a>
                <a className="Main__Links_Link" href='https://files.nix13.pw/' target='_blank' rel='noopener noreferrer'>
                    <i className="fa-light fa-files"/>
                </a>
            </div>
        </div>
    );
}

export default Main;