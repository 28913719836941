import './ProjectStackBadge.scss';

type Props = {
  stack: ProjectStack
};

const color = 'd8858d';

const stackImage = {
  css: `https://img.shields.io/badge/-CSS3-222?style=for-the-badge&logo=CSS3&logoColor=1572b6&color=${color}&labelColor=222`,
  html: `https://img.shields.io/badge/-HTML5-222?style=for-the-badge&logo=HTML5&logoColor=e34f26&color=${color}&labelColor=222`,
  js: `https://img.shields.io/badge/-JavaScript-222?style=for-the-badge&logo=CSS3&logoColor=f7df1e&color=${color}&labelColor=222`,
  ts: `https://img.shields.io/badge/-TypeScript-222?style=for-the-badge&logo=CSS3&logoColor=3178C6&color=${color}&labelColor=222`,
  sass: `https://img.shields.io/badge/-SASS-222?style=for-the-badge&logo=sass&logoColor=CC6699&color=${color}&labelColor=222`,

  java: `https://img.shields.io/badge/-Java-222?style=for-the-badge&logo=Java&logoColor=fff007396&color=${color}&labelColor=222`,
  kotlin: `https://img.shields.io/badge/-Kotlin-222?style=for-the-badge&logo=kotlin&logoColor=7f52ff&color=${color}&labelColor=222`,
  python: `https://img.shields.io/badge/-Python-222?style=for-the-badge&logo=python&logoColor=3776ab&color=${color}&labelColor=222`,
  go: `https://img.shields.io/badge/-GoLang-222?style=for-the-badge&logo=go&logoColor=00ADD8&color=${color}&labelColor=222`,

  react: `https://img.shields.io/badge/-React-222?style=for-the-badge&logo=react&logoColor=61DAFB&color=${color}&labelColor=222`,
  gulp: `https://img.shields.io/badge/-Gulp-222?style=for-the-badge&logo=gulp&logoColor=CF4647&color=${color}&labelColor=222`
};

const ProjectStackBadge = ({ stack }: Props) => (
  <img src={stackImage[stack]} alt={stack} className='ProjectStackBadge'/>
);

export default ProjectStackBadge;