import {request} from "../api";
import {GameList, Loading} from "../ui/ui";
import { useEffect, useState } from "react";

const ZenlessZoneDownloads = () => {
    const [zenlessZoneDownloads, setZenlessZoneDownloads] = useState<GameDLType[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        request('zzz')
            .then(setZenlessZoneDownloads)
            .then(_ => setLoading(false));
    }, []);

    if(loading) return <Loading/>;
    return (<GameList name={'Zenless Zone Zero'} anchor={'ZenlessZoneDownloads'} links={zenlessZoneDownloads}/>);
}

export default ZenlessZoneDownloads;