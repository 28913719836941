import {useEffect, useState} from "react";
import Loading from "../ui/Loading/Loading";
import {request} from "../api";
import {GameList} from "../ui/ui";

const GenshinDownloads = () => {
    const [genshinDL, setGenshinDL] = useState<GameDLType[]>([]);
    const [genshinAudio, setGenshinAudio] = useState<GameAudioType[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        request('genshin')
            .then(setGenshinDL)
            .then(_ => setLoading(false));

        request('genshin_audio').then(setGenshinAudio);
    }, []);

    if(loading || !genshinDL || !genshinAudio) return <Loading/>;
    return (<GameList name={'Genshin Impact'} anchor={'GenshinDownloads'} links={genshinDL} audio={genshinAudio}/>);
}

export default GenshinDownloads;