import { capitalize } from "../../utils";
import './ProjectStatusBadge.scss';

type Props = {
    status: ProjectStatus
}
const localized = {
    development: 'В разработке',
    release: 'Готово',
    freeze: 'Заморожено',
    dropped: 'Брошено'
}

const ProjectStatusBadge = ({ status }: Props) => (
  <div className={`ProjectStatus ProjectStatus__${capitalize(status)}`}>
    {localized[status]}
  </div>
);

export default ProjectStatusBadge;