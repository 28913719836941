import {FC} from "react";
import Main from "../../pages/Main/Main";
import Projects from "../../pages/Projects/Projects";
import './App.scss';
import Navbar from "../Navbar/Navbar";
import GenshinDownloads from "../../pages/GenshinDownloads";
import StarRailDownloads from "../../pages/StarRailDownloads";
import Mods from "../../pages/Mods/Mods";
import HonkaiDownloads from '../../pages/HonkaiDownloads'
import ZenlessZoneDownloads from "../../pages/ZenlessZoneDownloads";

const App: FC = () => (
    <div className="App">
        <Navbar/>

        <main className="Container">
            <Main/>
            <Projects/>
            <Mods/>
            <GenshinDownloads/>
            <StarRailDownloads/>
            <HonkaiDownloads/>
            <ZenlessZoneDownloads/>
        </main>
    </div>
);

export default App;