import './ProjectLinkBadge.scss';

type Props = {
  linkImage: ProjectLink
  linkText?: string
};

const color = 'd8858d';

const linkImages = {
  github: `https://img.shields.io/badge/-{0}-222?style=for-the-badge&logo=github&logoColor=ffffff&color=${color}&labelColor=222`,
  git: `https://img.shields.io/badge/-{0}-222?style=for-the-badge&logo=gitea&logoColor=609926&color=${color}&labelColor=222`,

  vk: `https://img.shields.io/badge/-{0}-222?style=for-the-badge&logo=VK&logoColor=0077ff&color=${color}&labelColor=222`,

  web: `https://img.shields.io/badge/-{0}-222?style=for-the-badge&logo=addthis&logoColor=e34f26&color=${color}&labelColor=222`,
};
const defaultLinksText = {
  github: 'GitHub',
  git: 'Git',
  vk: 'VK',
  web: 'Web'
}

const ProjectLinkBadge = ({ linkImage, linkText }: Props) => {
  let formattedString = linkImages[linkImage];
  formattedString = formattedString.replaceAll('{0}', linkText ?? defaultLinksText[linkImage]);

  return (
    <img src={formattedString} alt={linkText} className='ProjectLinkBadge'/>
  );
};

export default ProjectLinkBadge;