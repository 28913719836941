import {request} from "../api";
import {GameList, Loading} from "../ui/ui";
import { useEffect, useState } from "react";

const StarRailDownloads = () => {
    const [starRailDL, setStarRailDL] = useState<GameDLType[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        request('star_rail')
            .then(setStarRailDL)
            .then(_ => setLoading(false));
    }, []);

    if(loading || !starRailDL) return <Loading/>;
    return (<GameList name={'Star Rail'} anchor={'StarRailDownloads'} links={starRailDL}/>);
}

export default StarRailDownloads;