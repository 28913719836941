import ru from './lang/ru_ru.json';
import en from './lang/en_us.json';

const loadLangFile: (file: any) => { [key: string]: string } = (file: any) => JSON.parse(JSON.stringify(file));

const langs: { [key: string]: { [key: string]: string } } = {
    'ru-ru': loadLangFile(ru),
    'en-us': loadLangFile(en)
};

const i10n = (key: string) => {
    const lang = navigator.language.toLowerCase();
    const langFile = langs[lang];
    return langFile[key] || key;
}

export { i10n };