import React, {useEffect, useState} from "react";
import {request} from "../../api";
import Loading from "../Loading/Loading";
import './ModCard.scss';

type Props = {
    modId: string
} & React.HTMLAttributes<HTMLDivElement>;

const ModCard = ({ modId, ...restProps }: Props) => {
    const [modInfo, setModInfo] = useState<ModInfoType | null>(null);

    useEffect(() => {
        request(`mods/${modId}`)
            .then(setModInfo);
    }, [modId]);

    if(!modInfo) return <Loading/>;
    return (
        <div className={'ModCard'} {...restProps}>
            <div className="ModCard__Title">
                <h2>{modInfo.name}</h2>

                <div className="ModCard__Title_Version">{modInfo.recommended}</div>
            </div>
            <div className="ModCard__Desc">{modInfo.description}</div>
            <div className="ModCard__Badges">{modInfo.badges.map((badge, index) => (
                <a key={index} className={'ModCard__Badges_Item'} href={badge.url}><img src={badge.img} alt=""/></a>
            ))}</div>
        </div>
    );
};

export default ModCard;