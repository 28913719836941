import './Navbas.scss';
import {i10n} from "../../l10n/l10n";

const Navbar = () => (
    <nav className="Navbar">
        <div className="Navbar__Container">
            <a href="#Main" className='Navbar__Container_Link'>{i10n("navbar.main")}</a>
            <a href="#Projects" className='Navbar__Container_Link'>{i10n('navbar.projects')}</a>
            <a href="#Mods" className='Navbar__Container_Link'>{i10n('navbar.mods')}</a>
            <a href="#GenshinDownloads" className='Navbar__Container_Link'>GI</a>
            <a href="#StarRailDownloads" className='Navbar__Container_Link'>HSR</a>
        </div>
    </nav>
);

export default Navbar;