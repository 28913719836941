import {useState} from "react";
import './GameList.scss';

type Props = {
    name: string
    anchor: string
    links: GameDLType[]
    audio?: GameAudioType[]
};

const GameList = ({ name, anchor, links, audio }: Props) => {
    const [outdatedCollapse, setOutdatedCollapse] = useState(true);
    const [betaCollapse, setBetaCollapse] = useState(true);

    return (<div className={'GameList'} id={anchor}>
        <div className="GameList__Title">{name}</div>

        <span className={'GameList__Actual'}>Актуальная версия</span>
        {links.filter(dl => !dl.outdated && !dl.beta).map(dl => (
            <div className={'GameList__Item'} key={dl.name}>
                <h2 className={'GameList__Item_Title'}>{dl.name}</h2>

                <div className="GameList__Item_Links">
                    {dl.links.map(link => (
                        <a
                            href={link.url}
                            key={link.name}
                            className={'Button--Outline GameList__Item_Links_Link'}
                        >
                            {link.name}
                        </a>
                    ))}
                </div>
            </div>
        ))}

        {audio && <span className={'GameList__Actual'}>Озвучка</span>}
        {audio && audio.filter(dl => !dl.outdated).map(dl => (
            <div className={'GameList__Item'} key={dl.name}>
                <h2 className={'GameList__Item_Title'}>{dl.name}</h2>

                <div className="GameList__Item_Links">
                    {dl.links.map((link, index) => (
                        <a
                            href={link.url}
                            key={index}
                            className={'Button--Outline GameList__Item_Links_Link'}
                        >
                            {link.name}
                        </a>
                    ))}
                </div>
            </div>
        ))}

        <span className={`GameList__Outdated ${outdatedCollapse && 'GameList__Outdated--NotCollapsed'}`}
              onClick={() => setOutdatedCollapse(!outdatedCollapse)}>Устаревшие версии</span>
        {!outdatedCollapse && links.filter(dl => dl.outdated && !dl.beta).map(dl => (
            <div className={'GameList__Item'} key={dl.name}>
                <h2 className={'GameList__Item_Title'}>{dl.name}</h2>
                <div className="GameList__Item_Links">
                    {dl.links.map(link => (
                        <a
                            href={link.url}
                            key={link.name}
                            className={'Button--Outline GameList__Item_Links_Link GameList__Item_Links_Link--Outdated'}
                        >
                            {link.name}
                        </a>
                    ))}
                </div>
            </div>
        ))}

        <span className={`GameList__Beta ${betaCollapse && 'GameList__Beta--NotCollapsed'}`}
              onClick={() => setBetaCollapse(!betaCollapse)}>Бета версии</span>
        {!betaCollapse && links.filter(dl => dl.beta).map(dl => (
            <div className={'GameList__Item'} key={dl.name}>
                <h2 className={'GameList__Item_Title'}>{dl.name}</h2>
                <div className="GameList__Item_Links">
                    {dl.links.map(link => (
                        <a
                            href={link.url}
                            key={link.name}
                            className={'Button--Outline GameList__Item_Links_Link GameList__Item_Links_Link--Beta'}
                        >
                            {link.name}
                        </a>
                    ))}
                </div>
            </div>
        ))}
    </div>)
}

export default GameList;