import {useEffect, useState} from "react";
import ProjectCard from "../../ui/ProjectCard/ProjectCard";
import './Projects.scss';
import Loading from "../../ui/Loading/Loading";
import {request} from "../../api";

type ProjectsState = {
    done: ProjectType[]
    dropped: ProjectType[]
}

const Projects = () => {
    const [projects, setProjects] = useState<ProjectsState>({done: [], dropped: []});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        request('projects')
            .then(setProjects)
            .then(_ => setLoading(false));
    }, []);

    if(loading) return <Loading/>;
    return (
        <div className="Projects" id='Projects'>
            <div className="Projects_Title">Проекты</div>

            <span className='Projects__Title' style={{ borderColor: '#ab1bff', color: '#ab1bff' }}>Завершенные и в разработке</span>
            <div className="Projects__List">
                {projects.done.map(project => (
                    <ProjectCard
                        key={project.name}
                        title={project.name} status={project.status}
                        links={project.links} stack={project.stack}
                    >{project.desc}</ProjectCard>
                ))}
            </div>

            <span className='Projects__Title' style={{ borderColor: '#19b3a6', color: '#19b3a6' }}>Замороженные и брошенные</span>
            <div className="Projects__List">
                {projects.dropped.map(project => (
                    <ProjectCard
                        key={project.name}
                        title={project.name} status={project.status}
                        links={project.links} stack={project.stack}
                    >{project.desc}</ProjectCard>
                ))}

            </div>
        </div>
    );
}

export default Projects;