import React from "react";
import ProjectLinkBadge from "../ProjectBadge/ProjectLinkBadge";
import ProjectStackBadge from "../ProjectBadge/ProjectStackBadge";
import ProjectStatusBadge from "../ProjectBadge/ProjectStatusBadge";
import './ProjectCard.scss';

type Props = {
  title: string
  status?: 'development' | 'release' | 'freeze' | 'dropped'
  links?: ProjectLinkType[]
  stack?: ProjectStack[]
  children?: any
}

const ProjectCard = ({ title, status, links, stack, children }: Props) => (
  <div className="ProjectCard">
    <h2 className="ProjectCard__Title">
      {title}

      {status && <ProjectStatusBadge status={status}/>}
    </h2>

    {children && <div className="ProjectCard__Content">
      {children}
    </div>}

    {stack && stack.length > 0 && (
        <div className="ProjectCard__Stack">
          {stack.map(s => (
            <ProjectStackBadge stack={s} key={s}/>
          ))}
        </div>
    )}

    {links && links.length > 0 && <div className="ProjectCard__Links">
      {links.map(link => (
        <a className="ProjectCard__Links_Link" href={link.url} target='_blank' rel='noreferrer' key={link.name} title={link.name}>
          <ProjectLinkBadge linkImage={link.type} linkText={link.name}/>
        </a>
      ))}
    </div>}
  </div>
);

export default ProjectCard;