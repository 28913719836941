import {request} from "../api";
import {GameList, Loading} from "../ui/ui";
import { useEffect, useState } from "react";

const HonkaiDownloads = () => {
    const [honkaiDownload, setHonkaiDownload] = useState<GameDLType[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        request('honkai_impact')
            .then(setHonkaiDownload)
            .then(_ => setLoading(false));
    }, []);

    if(loading || !honkaiDownload) return <Loading/>;
    return (<GameList name={'Honkai: Impact'} anchor={'HonkaiDownloads'} links={honkaiDownload}/>);
}

export default HonkaiDownloads;