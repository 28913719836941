import {useEffect, useState} from "react";
import Loading from "../../ui/Loading/Loading";
import {request} from "../../api";
import ModCard from "../../ui/ModCard/ModCard";
import './Mods.scss';

const Mods = () => {
    const [mods, setMods] = useState<ModsListType>({available: [], developed: []});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        request('mods')
            .then(setMods)
            .then(_ => setLoading(false));
    }, []);

    if(loading) return <Loading/>;
    return (
        <div className={'Mods'} id='Mods'>
            <div className="Mods__Title">Моды</div>

            <div className="Mods__Items">
                {mods.available.map((modId, index) => (
                    <ModCard key={modId} style={{ marginRight: index % 2 === 1 ? 0 : 8 }} modId={modId}/>
                ))}
            </div>
        </div>
    )
};

export default Mods;